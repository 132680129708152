import { iconTask } from "@/design/icon/iconConst";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

export const taskIconMixin = {
  mixins: [menuItemSettingsMixin],
  data() {
    return {
      iconTask: iconTask
    };
  }
};
