<template>
  <v-icon :color="iconColorClass">
    {{ iconTask }}
  </v-icon>
</template>

<script>
// model
import {
  dueDateColorClass,
  findTaskStateColorClass,
  taskState
} from "@/model/workflow/task/taskModel";

// mixins
import { taskIconMixin } from "@/mixins/shared/workflow/task/taskIconMixin";

export default {
  name: "TaskIcon",
  mixins: [taskIconMixin],
  props: {
    /**
     * @type {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}}
     */
    task: undefined
  },
  computed: {
    iconColorClass() {
      if (!this.task) {
        return undefined;
      }

      if ((this.task?.status ?? -1) !== taskState.completed) {
        const ddCC = dueDateColorClass(this.task);
        if (ddCC) {
          return ddCC;
        }
      }

      return findTaskStateColorClass(this.task?.status) ?? undefined;
    }
  }
};
</script>
